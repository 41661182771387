export const pageFrom = data => {
  return 1 + (data.page - 1) * data.recordsperpage;
};
export const pageTo = (data, recordsPerPage) => {
  const to = recordsPerPage + (data.page - 1) * data.recordsperpage;
  return to > data.count ? data.count : to;
};
export const findFilter = (filtersId_, filterKey, filterValue) => {
  if (filterValue < 0) {
    return undefined;
  }
  return filtersId_.find(map => map.get('type').toLowerCase() === filterKey.toLowerCase()).get("items").find(d => d.get('id') === filterValue).get('value');
};
export const API_FILTERS_TO_AMP_FILTERS = new Map([['donor', 'donor-agency'], ['country', 'administrative-level-0'], ['pillar', 'national-planning-objectives-level-1'], ['projectStatus', 'status']]);
export const API_FILTERS_TO_SCHEME = new Map([['donor', 'donor'], ['country', 'locations'], ['pillar', 'nationalPlanningObjectives'], ['projectStatus', 'activityStatus'], ['currency', 'currency-code']]);
export const API_COLUMNS_TO_AMP_COLUMNS = new Map([['donor-agency', 'Donor Agency'], ['national-planning-objectives-level-1', 'National Planning Objectives Level 1'], ['administrative-level-0', 'Administrative Level 0'], ['activity-id', 'Activity Id'], ['actual-start-date', 'Actual Start Date'], ['actual-completion-date', 'Actual Completion Date'], ['proposed-start-date', 'Proposed Start Date'], ['proposed-completion-date', 'Proposed Completion Date'], ['project-title', 'Project Title'], ['percentage-of-implementation', 'percentage-of-implementation']]);
export const AMP_ID_COLUMN = 'activity-id';
export const getElementsFromArray = originalArray => {
  const elements = new Map();
  originalArray.forEach(obj => {
    elements.set(obj.name, obj.id);
  });
  return elements;
};
export const getElements = (originalArray, maxLevel) => {
  let level = 0;
  const elements = new Map();
  originalArray.forEach(s => {
    if (s.children) {
      s.children.forEach(ss => {
        addToMap(ss, elements, level, maxLevel);
      });
    }
  });
  return elements;
};
const addToMap = (obj, elements, level, maxLevel) => {
  level++;
  if (!obj.children || maxLevel !== undefined && level >= maxLevel) {
    elements.set(obj.name, obj.id);
    return;
  }
  obj.children.forEach(s => addToMap(s, elements, level, maxLevel));
};
export const populateListDefinitions = (filterObject, scheme, setListDefinitions) => {
  if (filterObject) {
    setListDefinitions(prevDefinitions => {
      const newDefinitions = new Map(prevDefinitions);
      const listDefinition = filterObject.listDefinitions.find(ld => ld.name.toLowerCase().replace(/\s/g, '') === scheme.toLowerCase());
      //TODO make this more generic
      if (scheme === 'donor' || scheme === 'nationalPlanningObjectives') {
        const maxLevel = scheme === 'nationalPlanningObjectives' ? 1 : undefined;
        newDefinitions.set(scheme, getElements(filterObject.items[listDefinition.items], maxLevel));
      } else {
        newDefinitions.set(scheme, getElementsFromArray(filterObject.items[listDefinition.items]));
      }
      return newDefinitions;
    });
  }
};